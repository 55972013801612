<template>
<v-container fluid>
  <v-row>
    <!-- side bar -->
    <div>
      <v-navigation-drawer permanent absolute width="200">
        <v-container fluid>
          <v-btn @click="openNewGroupDlg" color="primary" text>添加群组 +</v-btn>
          <div class="mx-n2">
            <v-card @click="currentIndex = i" elevation="0" class="my-1 pt-1" height="30" v-for="(group, i) in groups" :key="i">
              <div class="d-flex px-2">
                <v-icon class="mr-1" small>mdi-tag-outline</v-icon>
                <div :class="currentIndex==i ? 'blue--text': ''">{{ group.team_name }}</div>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{attrs, on}">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-btn @click="openUpdateGroupDlg(i)" small text>编辑</v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn @click="deleteGroup(i)" small text>删除</v-btn>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </v-card>
          </div>
        </v-container>
      </v-navigation-drawer>
    </div>
    <!-- banner -->
    <v-banner color="#E3F2FD"  width="100%">
      <v-container fluid>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn @click="removeMembers" color="blue" outlined class="mx-1">移出</v-btn>
          <!-- <v-btn color="blue" outlined class="mx-1">批量导入</v-btn> -->
          <v-btn @click="openUserDepartmentPicker" class="mx-1" dark color="blue">添加成员</v-btn>
        </div>
      </v-container>
    </v-banner>
    <!-- content -->
    <div v-if="groupUsers.length > 0" style="width:100%;padding-left:200px;">
      <div class="mx-5 my-3 d-flex align-center">
        <!-- departments of group -->
        <div class="d-flex">
          <div class="text-subtitle-2">所含部门/群组：</div>
          <div class="d-flex align-center mx-1" v-for="(depart, i) in incluededDepartment" :key="i">
            <v-icon class="mx-1" small>mdi-lan</v-icon>
            <div class="text-caption">{{ depart | department }}</div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <!-- search box -->
        <div style="width:200px;">
          <v-text-field prepend-inner-icon="mdi-magnify" dense v-model="search" outlined></v-text-field>
        </div>
      </div>
      <v-data-table :search="search" :headers="headers" :items="groupUsers" show-select :single-select="false" item-Key="id" v-model="selected">
        <template v-slot:[`item.employeeName`]="{ item }">
          <span class="text-caption blue--text font-weight-light">{{ item.employeeName }}</span>
          <span v-if="item.user_role=='admin' || item.user_role=='creator'" class="text-caption blue--text font-weight-light">(老板)</span>
          <span v-else-if="item.leader_dps" class="text-caption blue--text font-weight-light">(部门领导)</span>
        </template>
        <template v-slot:[`item.deptId`]="{ item }">
          {{ item.deptId | department }}
        </template>
      </v-data-table>
    </div>
    <div v-else style="width:100%;height:600px;display:flex;margin-left:200px;">
      <div style="margin:auto;text-align:center">
        <div class="text-subtitle-1">当前群组下无员工</div>
        <div class="mt-3">
          <v-btn @click="openUserDepartmentPicker" color="blue" outlined>立即添加</v-btn>
          <!-- <v-btn color="primary" small>批量导入</v-btn> -->
        </div>
      </div>
    </div>
  </v-row>
  <new-group-dlg @agree="handleGroup" :group="groupToEdit" ref="newGroupDlg"></new-group-dlg>
  <group-confirm-dlg ref="confirmDlg"></group-confirm-dlg>
  <user-department-team-picker @selected="updateCollaborates" ref="userDepartmentTeamPicker"></user-department-team-picker>
</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'GroupManagement',
  components: {
    NewGroupDlg: () => import('@/components/admin/group/NewGroupDlg.vue'),
    GroupConfirmDlg: () => import('@/components/admin/group/GroupConfirmDlg.vue'),
    UserDepartmentTeamPicker: () => import('@/components/common/UserDepartmentTeamPicker.vue'),
  },
  data: () => ({
    newGroup: {
      team_name: '',
      team_company_id: null,
      team_collaborates: '',
    },
    groupToEdit: null,
    currentIndex: 0,
    headers: [
      {
        text: '姓名',
        align: 'start',
        sortable: false,
        value: 'employeeName'
      },
      {
        text: '手机号',
        value: 'phone'
      },
      {
        text: '邮箱',
        value: 'email'
      },
      {
        text: '	部门',
        value: 'deptId'
      }
    ],
    search: '',
    selected: []
  }),
  computed: {
    ...mapGetters('group', ['groups']),
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('user', ['users']),
    groupUsers: function () {
      if(!this.groups[this.currentIndex]) return [];
      if(!this.groups[this.currentIndex].team_members) return [];
      let users = [];
      this.groups[this.currentIndex].team_members.split(',').map(member => {
        const index = this.users.findIndex(user => user.id == member);
        if(index !== -1) {
          users.push(this.users[index]);
        }
      });
      return users;
    },
    incluededDepartment: function () {
      if(!this.groups[this.currentIndex]) return [];
      if(!this.groups[this.currentIndex].team_collaborates) return [];
      let departments = [];
      this.groups[this.currentIndex].team_collaborates.split(',').map(depart => {
        if(depart.includes('d')) {
          departments.push(depart.substring(1));
        }
      })
      return departments;
    }
  },
  methods: {
    ...mapActions('group', ['getGroups', 'addGroup', 'updateGroup', 'removeGroup']),
    ...mapActions('app', ['setNotification']),
    openNewGroupDlg() {
      this.groupToEdit = Object.assign({}, this.newGroup);
      this.$refs.newGroupDlg.open();
    },
    handleGroup(e) {
      this.groupToEdit.team_company_id = this.authUser.user_company_id;
      if(e=='create') {
        this.addGroup(this.groupToEdit);
      }
      if(e=='update') {
        this.updateGroup(this.groupToEdit);
      }
    }, 
    openUpdateGroupDlg(index) {
      this.groupToEdit = Object.assign({}, this.groups[index]);
      this.$refs.newGroupDlg.open(this.groups[index].team_collaborates);
    },
    async deleteGroup(index) {
      if(await this.$refs.confirmDlg.open('确定要删除该群组吗？', '删除群组不会导致成员从组织架构中删除', {width: 350})) {
        this.removeGroup({
          team_id: this.groups[index].team_id,
        })
      }
    },
    updateCollaborates(e) {
      let temp = [];
      let users = [];
      e.user.map(u => {
        temp.push(`u${u}`);
        users.push(u);
      });
      e.depart.map(d => {
        temp.push(`d${d}`);
        this.users.filter(user => user.deptId == d).map(user => {
          users.push(user.id);
        })
      });
      this.updateGroup({
        team_id: this.groups[this.currentIndex].team_id,
        team_collaborates: temp.join(),
        team_members: users.join()
      });
    },
    openUserDepartmentPicker() {
      this.$refs.userDepartmentTeamPicker.openDialog(this.groups[this.currentIndex].team_collaborates);
    },
    removeMembers() {
      if(this.selected.length == 0) {
        this.setNotification({
          status: 'warning',
          text: '请至少选择一名用户'
        });
        return ;
      }
      let members = this.groups[this.currentIndex].team_members.split(',').filter(m => m !== '');
      this.selected.map(member => {
        const index = members.findIndex(user => user == member.id);
        if(index !== -1) {
          members.splice(index, 1);
        }
      });
      this.updateGroup({
        team_id: this.groups[this.currentIndex].team_id,
        team_members: members.join()
      });
    }
  },
  mounted() {
    this.getGroups({
      team_company_id: this.authUser.user_company_id
    })
  }
}
</script>